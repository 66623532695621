import React, { useEffect } from "react";

// Import icons and images
import { ArrowRightOutline } from "../../assets/icons";
import { TraineesOpinionsNavbarBgImg } from "../../assets/images";

// IMPORT STYLES
import "../../index.css";
import styles from "./TraineesOpinions.module.css";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Navbar, WhatsappGroupBanner } from "../../components";
import TraineesOpinionsSliders from "../../components/traineesOpinionsSliders/TraineesOpinionsSliders";

const TraineesOpinions = () => {
	const navigate = useNavigate();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<HelmetProvider>
			<Helmet>
				<title>آراء المتدربين</title>
				<meta
					name='description'
					content='آراء المتدربين السابقين في برنامج الرحلة التجارية إلى الصين'
				/>
				,ةث
			</Helmet>

			<div className={`openPages bg-white h-full overflow-y-auto`}>
				{/*Navbar */}
				<Navbar
					background={"bg-red"}
					NavbarBgImg={TraineesOpinionsNavbarBgImg}
					pageTitle={
						<h3 className=' w-64  text-white text-xl font-Tajawal font-normal '>
							آراء المتدربين السابقين في برنامج الرحلة التجارية إلى الصين
						</h3>
					}
				/>
				{/*------------------------------------------*/}

				<div className='container'>
					{/* back to home icon */}
					<div className='flex justify-start gap-6 mb-5'>
						<ArrowRightOutline
							className=' cursor-pointer'
							onClick={() => navigate("/business-trip-program-to-china")}
						/>
					</div>

					{/* Trainees opinions images and videos slider */}
					<TraineesOpinionsSliders />

					{/* subscribe on training program button */}
					<button
						onClick={() => navigate("/subscribe-training-program")}
						className='btn_style bg-red  text-black mb-12'>
						الاشتراك في البرنامج التدريبي
					</button>
				</div>

				{/* Pages Routs  */}
				<WhatsappGroupBanner />
			</div>
		</HelmetProvider>
	);
};

export default TraineesOpinions;
