import * as React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
	const navigate = useNavigate();

	return (
		<div
			className='w-full h-screen  flex flex-col justify-center items-center text-center p-4 bg-red'
			id='error-page'>
			<h1 className='mb-2 text-white text-lg'>يبدو أنك تائه!</h1>
			<p className='mb-4 text-white text-xl'>
				الصفحة التي تبحث عنها غير متوفرة!
			</p>

			<button
				className='w-max h-[49px]  text-white border-b-[0.19rem] border-b-[#FFF] text-xl font-Tajawal font-medium'
				onClick={() => navigate("/")}>
				الصفحة الرئيسية
			</button>
		</div>
	);
};

export default NotFoundPage;
