import React from "react";
import { useNavigate } from "react-router-dom";

// import css styles
import styles from "./PagesLinks.module.css";

const PagesLinks = ({ arrow_icon, title, route }) => {
	const navigate = useNavigate();
	return (
		<div className='text-center w-40 mx-auto ' onClick={() => navigate(route)}>
			<h4
				className={`text-[#E62237] ${styles.border_bottom} mb-5 pb-2 text-[22px] font-medium`}>
				{title}
			</h4>

			{/* Arrow Down Icon*/}
			<div className=' flex justify-center items-center'>{arrow_icon}</div>
		</div>
	);
};

export default PagesLinks;
