import React, { Fragment, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactDom from "react-dom";

// import css styles files
import "../../index.css";
import styles from "./SuccessMessageModal.module.css";

// Import context
import Context from "../../context/Context";

// Icons
import { CloseIcon } from "../../assets/icons";
import { ThanksImage } from "../../assets/images";

const MoreArticleInfo = () => {
	// to handle open success modal message after click register button
	const location = useLocation();
	const navigate = useNavigate();

	const successMessageContext = useContext(Context);
	const { openSuccessMessage, setOpenSuccessMessage } = successMessageContext;

	if (location.pathname === "/success-message") {
		setOpenSuccessMessage(true);
	} else {
		setOpenSuccessMessage(false);
	}

	return (
		openSuccessMessage && (
			<div>
				{/* Backdrop */}
				<div className={`${styles.backdrop} z-20`} />
				<div
					className={` ${styles.z_index_100} openModal fixed left-0 top-[80px] `}>
					<div className='transform min-w-[430px] h-full bg-[#2DF170] align-middle shadow-xl transition-all px-5 pt-2 pb-4 '>
						<div className=' w-full h-7 flex items-center justify-end'>
							{/* Close Icon */}
							<div className='cursor-pointer border-[1px] border-solid border-white rounded-full '>
								<CloseIcon
									className={styles.Close_icon}
									onClick={() => {
										setOpenSuccessMessage(false);
										navigate("/");
									}}
								/>
							</div>
						</div>

						{/* modal content */}
						<div className=' flex flex-col justify-center items-center '>
							<div className='mb-4'>
								<img src={ThanksImage} alt='' />
							</div>

							<div className='text-center '>
								<h5 className='text-2xl font-bold  text-white mb-2'>
									تم الاشتراك بنجاح
								</h5>
								<p className='text-xl font-medium text-white '>
									سيتم مراجعة طلبك والتواصل معك
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

const SuccessMessageModal = () => {
	return (
		<Fragment>
			{ReactDom.createPortal(
				<MoreArticleInfo />,
				document.getElementById("success_message_modal")
			)}
		</Fragment>
	);
};

export default SuccessMessageModal;
