import React, { useEffect } from "react";

// import css styles
import "../../index.css";
import styles from "./CommonQuestions.module.css";
import { ArrowRight } from "../../assets/icons";
import {
	CommonQuestionAccording,
	Navbar,
	WhatsappGroupBanner,
} from "../../components";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { QuestionImage } from "../../assets/images";

const CommonQuestions = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<HelmetProvider>
			<Helmet>
				<title> الأسئلة الشائعة</title>
			</Helmet>

			<div className={`openPages bg-[#FDF1F3] h-full overflow-y-auto `}>
				{/*Navbar */}
				<Navbar
					background={"bg-red"}
					pageTitle={
						<>
							<h3 className=' w-max text-white text-xl font-Tajawal font-normal pl-5'>
								الأسئلة الشائعة
							</h3>
							<img src={QuestionImage} alt='' loading='lazy' />
						</>
					}
				/>
				{/*------------------------------------------*/}

				<div className='container'>
					{/* back to home icon */}
					<div className='flex justify-start gap-4 mb-8'>
						<ArrowRight
							className='w-[34px] h-[34px] cursor-pointer'
							onClick={() =>
								navigate("/registration-form-for-the-training-program")
							}
						/>
						<div className=' text-right text-xl font-medium font-Tajawal text-black pl-4'>
							الأسئلة الشائعة حول برنامج الرحلة التجارية إلى الصين
						</div>
					</div>

					{/* Common Question According Component*/}
					<CommonQuestionAccording />
				</div>

				{/* packages info */}
				<WhatsappGroupBanner />
			</div>
		</HelmetProvider>
	);
};

export default CommonQuestions;
