import React, { useState } from "react";

// components
import { VideoComponent } from "..";

// import css styles
import "../../index.css";
import styles from "./TraineesOpinionsSliders.module.css";

// import icons and images
import { PlayVideo } from "../../assets/icons";
import VideoDemo from "../../assets/video.mp4";
import { Slider1, Slider2, SliderPreview } from "../../assets/images";

const TraineesOpinionsData = [
	{ id: 1, image: VideoDemo },
	{ id: 2, image: SliderPreview },
	{ id: 3, image: VideoDemo },
	{ id: 4, image: SliderPreview },
	{ id: 5, image: SliderPreview },
	{ id: 6, image: VideoDemo },
	{ id: 7, image: SliderPreview },
	{ id: 8, image: VideoDemo },
	{ id: 9, image: SliderPreview },
	{ id: 10, image: SliderPreview },
	{ id: 11, image: VideoDemo },
	{ id: 12, image: SliderPreview },
];

const TraineesOpinionsSliders = () => {
	const [sliderPreviewImage, setSliderPreviewImage] = useState(VideoDemo);

	// slider
	const sliders = TraineesOpinionsData.map((slider) => {
		const isVideo = slider.image?.includes(
			// "video/mp4" || "video/avi" || "video/mov" || "video/mkv"
			".mp4"
		);

		if (isVideo) {
			return (
				<div key={slider.id} className={`${styles.slider_images_box}`}>
					<div className=' h-full'>
						<img
							onClick={() => setSliderPreviewImage(slider?.image)}
							className={`${styles.slider_image}`}
							src={Slider2}
							alt=''
							loading='lazy'
						/>
					</div>
				</div>
			);
		} else {
			return (
				<div key={slider.id} className={`${styles.slider_images_box}`}>
					<img
						onClick={() => setSliderPreviewImage(slider?.image)}
						className={`${styles.slider_image}`}
						src={slider?.image}
						alt=''
						loading='lazy'
					/>
				</div>
			);
		}
	});

	return (
		<div className={`${styles.sliders_Container}`}>
			{/* Preview images or video */}
			<div className={`${styles.main_slider_preview}`}>
				{sliderPreviewImage.includes(
					// "video/mp4" || "video/avi" || "video/mov" || "video/mkv"
					".mp4"
				) ? (
					<VideoComponent
						className={`${styles.image_preview}`}
						src={sliderPreviewImage}
						poster={SliderPreview}
					/>
				) : (
					<img
						className={`${styles.image_preview}`}
						src={sliderPreviewImage}
						alt=''
						loading='lazy'
					/>
				)}
			</div>
			{/* Trainees opinions images and videos slider */}
			<div className={`${styles.sliders_wrapper}`}>{sliders}</div>
		</div>
	);
};

export default TraineesOpinionsSliders;
