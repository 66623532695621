import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoJS = ({ src, poster }) => {
	const videoRef = React.useRef(null);
	const playerRef = React.useRef(null);

	// This options to handle video play
	const videoJsOptions = {
		autoplay: false,
		controls: true,
		responsive: true,
		fluid: true,
		poster: poster,
		sources: [
			{
				src: src,
				type: "video/mp4",
			},
		],
	};

	React.useEffect(() => {
		// Make sure Video.js player is only initialized once
		if (!playerRef.current) {
			// The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
			const videoElement = document.createElement("video-js");

			videoElement.classList.add("vjs-big-play-centered");
			videoElement.classList.add("rounded-lg");
			videoElement.classList.add("video_height");
			videoRef.current.appendChild(videoElement);

			// we use this to add the
			playerRef.current = videojs(videoElement, videoJsOptions);
		} else {
			// to handle add poster to the video
			const player = playerRef.current;
			player.src(videoJsOptions.sources);
		}
	}, [videoRef]);

	// Dispose the Video.js player when the functional component unmounts
	React.useEffect(() => {
		const player = playerRef.current;

		return () => {
			if (player && !player.isDisposed()) {
				player.dispose();
				playerRef.current = null;
			}
		};
	}, [playerRef]);

	return (
		<div data-vjs-player className='h-full'>
			<div ref={videoRef} className='h-full' />
		</div>
	);
};

export default VideoJS;
