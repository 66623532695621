import React, { useState } from "react";

import { Link } from "react-router-dom";

// to create menu icon
import { Squash } from "hamburger-react";

// import css styles files
import "../../index.css";
import styles from "./Navbar.module.css";
import NavbarLogo from "./navbarLogo/NavbarLogo";
import { Instagram, SnapChat, TikTok, Twitter } from "../../assets/icons";

const navigation = [
	{ name: "الرئيسية", href: "/", current: true },
	{
		name: "نبذة عن فايز بن صالح",
		href: "/about-fayez-bin-saleh",
		current: false,
	},
	{ name: "المدونة", href: "/blog", current: false },
	{
		name: "البرنامج التدريبي",
		href: "/business-trip-program-to-china",
		current: false,
	},
	// { name: "آراء المتدربين", href: "/trainees-opinions", current: false },

	{ name: "الأسئلة الشائعة", href: "/common-questions", current: false },

	{ name: "خدمة العملاء", href: "/customer-service", current: false },
];
const Navbar = ({ background, NavbarBgImg, pageTitle }) => {
	// to open and close menu
	const [isOpen, setIsOpen] = useState(false);

	// this function to joining classes together.
	function classNames(...classes) {
		return classes.filter(Boolean).join(" ");
	}

	return (
		<div
			as='nav'
			className={` relative z-50 mb-5 ${background}`}
			style={{
				backgroundRepeat: "no-repeat",
				backgroundImage: `url(${NavbarBgImg})`,
			}}>
			<>
				<div className='container'>
					<div className='relative h-[90px] flex  items-center justify-between'>
						<div className='absolute inset-y-0 left-0 flex items-center '>
							{/* Mobile menu button*/}
							<Squash
								size={22}
								duration={0.6}
								color='#ffff'
								toggled={isOpen}
								toggle={setIsOpen}
							/>
						</div>

						{/* website logo */}
						<div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
							<div className='flex flex-shrink-0 items-center'>
								<NavbarLogo pageTitle={pageTitle} />
							</div>
							<div className='hidden sm:ml-6'>
								<div className='flex space-x-4'>
									{navigation.map((item) => (
										<Link
											key={item.name}
											to={item.href}
											className={classNames(
												item.current
													? "bg-nav_bg text-white"
													: "text-white hover:text-white",
												"rounded-md px-3 py-2 text-sm font-medium"
											)}
											aria-current={item.current ? "page" : undefined}>
											{item.name}
										</Link>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>

				{/*menu style*/}
				<div
					className={classNames(
						isOpen ? `${styles.menuStyle} ` : `${styles.hiddenMenu} `,
						" bg-yellow absolute w-full z-50 text-center"
					)}>
					<div className='space-y-1 px-2 pb-3 pt-2'>
						{navigation.map((item) => (
							<Link
								key={item.name}
								to={item.href}
								className={classNames(
									"block font-Tajawal rounded-md px-3 py-2 text-lg font-medium bg-transparent text-black2"
								)}
								aria-current={item.current ? "page" : undefined}>
								{item.name}
							</Link>
						))}

						<div className=' flex justify-center items-center gap-4 '>
							<a
								className='flex justify-center items-center cursor-pointer bg-white rounded-full w-8 h-8 p-2'
								href=' https://snapchat.com/add/fayezbinsaleh'
								target='_blanck'>
								<SnapChat />
							</a>

							<a
								className='flex justify-center items-center cursor-pointer  bg-white rounded-full w-8 h-8 p-2'
								href=' https://twitter.com/FayezbinSaleh'
								target='_blanck'>
								<Twitter />
							</a>

							<a
								className='flex justify-center items-center cursor-pointer  bg-white rounded-full w-8 h-8  p-2'
								href='https://instagram.com/fayezbinsaleh'
								target='_blanck'>
								<Instagram />
							</a>

							<a
								className='flex justify-center items-center cursor-pointer  bg-white rounded-full w-8 h-8 p-2'
								href=' https://www.tiktok.com/@fayezbinsaleh'
								target='_blanck'>
								<TikTok />
							</a>
						</div>
					</div>
				</div>
			</>
		</div>
	);
};

export default Navbar;
