import React, { useContext, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Context from "../../../context/Context";

// import components
import Navbar from "../../navbar/Navbar";

// import icons
import { ArrowRight, Logo } from "../../../assets/icons";

// import styles
import styles from "./ArticleContent.module.css";

const ArticleContent = () => {
	const navigate = useNavigate();

	//  to send article content to articleContent page
	const blogContext = useContext(Context);
	const { blogArticleContent } = blogContext;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<HelmetProvider>
			<Helmet>
				<title> {blogArticleContent?.title} </title>
				<meta name='description' content={blogArticleContent?.title} />
			</Helmet>

			<div className={`pb-12`}>
				{/*Navbar */}
				<Navbar
					background={"bg-red"}
					pageTitle={
						<img
							onClick={() => navigate("/")}
							className='h-[53px] max-w-[98px] w-auto cursor-pointer'
							src={Logo}
							alt='website logo'
						/>
					}
				/>

				{/*------------------------------------------*/}
				<div className='container'>
					{/*back to home icon*/}
					<div
						className={`${styles.arrow_right} flex justify-start items-center gap-6 mb-5`}>
						<ArrowRight
							className='w-[34px] h-[34px] cursor-pointer'
							onClick={() => navigate("/blog")}
						/>
					</div>

					<div className='w-full'>
						<div className='mb-2'>
							<img
								className=' rounded w-full'
								src={blogArticleContent.cover}
								alt={blogArticleContent.title}
								loading='lazy'
							/>
							<h4 className=' text-2xl text-center text-black font-Tajawal font-bold py-2 px-3 mb-4 '>
								{blogArticleContent.title}
							</h4>

							{blogArticleContent.articleContent}
						</div>
					</div>
				</div>
			</div>
		</HelmetProvider>
	);
};

export default ArticleContent;
