import React, { Fragment, useContext } from "react";
import ReactDom from "react-dom";

// import css styles files
import "../../index.css";
import styles from "./ProgramRegistrationPolicyModal.module.css";

// Import context
import Context from "../../context/Context";

// Icons
import { CloseIcon } from "../../assets/icons";

const ProgramRegistrationPolicy = () => {
	const PolicyModalContext = useContext(Context);
	const {
		openMoreInfoModal,
		setOpenMoreInfoModal,
		title,
		btnContent,
		setAcceptCondition,

		setAcceptPolicy,
	} = PolicyModalContext;

	// this function to handle close this modal and set accept condition to true
	const handleAcceptConditionFunction = () => {
		setOpenMoreInfoModal(false);

		if (btnContent === "أوافق على الشروط") {
			setAcceptCondition(true);
		} else if (btnContent === "أوافق على تكملة التسجيل في البرنامج") {
			setAcceptPolicy(true);
		}
	};

	return (
		<div>
			{openMoreInfoModal && (
				<div onClose={() => setOpenMoreInfoModal(true)}>
					{/* Backdrop */}
					<div className={`${styles.backdrop} z-20`} />
					<div
						className={`${styles.z_index_100} openModal fixed left-0  top-[80px] w-full min-w-[380px] max-w-[428px] h-full `}>
						<div className='flex items-center justify-center p-4 text-center '>
							<div className='transform w-full h-full bg-white align-middle shadow-xl transition-all'>
								{/* Head of modal */}
								<div className='bg-yellow w-full h-11 flex items-center '>
									{/* Modal Title */}
									<h3 className='text-lg flex-1 h-full font-medium leading-6 text-black text-center flex justify-center items-center'>
										{title}
									</h3>

									{/* Close Icon */}
									<div className='ml-2 cursor-pointer'>
										<CloseIcon onClick={() => setOpenMoreInfoModal(false)} />
									</div>
								</div>

								{/* modal content */}
								<div className='h-[600px] py-6  overflow-y-auto flex flex-col justify-start'>
									<div className=' h-full text-lg text-[#200407] text-right font-normal mb-5 pr-4'>
										{title === "احتياطات أثناء السفر للصين" ? (
											<>
												<ul className='list-decimal pr-4 mb-4'>
													<li className='  mb-1'>جواز سفر صالح لمدة 6 شهور</li>
													<li className='  mb-1'>تأشيرة السفر الى الصين</li>
													<li className='  mb-1'>
														يفضل استخراجها قبل موعد الرحلة بثلاث أسابيع
													</li>
												</ul>

												<div className=' text-lg font-normal'>
													<span className='text-[#e72337]'>ملاحظة:</span> يمكن
													استخراج التأشيرة عن طريق أحد المكاتب المعتمدة في
													السعودية
												</div>
											</>
										) : (
											<ul className='list-decimal pr-4'>
												<li className='  mb-1'>يلزم جنسية خليجي فقط</li>
												<li className='  mb-1'>لا يقل عمره عن 25 سنة </li>
												<li className='  mb-1'>
													يمنع أصحاب المرافقين بدون دفع رسوم الاشتراك
												</li>
												<li className='  mb-1'>الالتزام بفقرات البرنامج </li>
												<li className='  mb-1'>
													تغيبك عن الحظور يخصم 35% من قيمة الدورة
												</li>
												<li className='  mb-1'>
													احترام المشتركين والقائمين على البرنامج التدريبي
												</li>
												<li className='  mb-1'>الالتزام بالاداب العامة</li>
												<li className='  mb-1'>
													احترام الأنظمة والقوانين الخاصة بدولة الصين
												</li>
												<li className=''>
													المشرف له الحق في استبعادك لو تجاوزت البنود
												</li>
											</ul>
										)}
									</div>
									<button
										onClick={() => handleAcceptConditionFunction()}
										className='btn-style self-end  h-[56px] text-white w-[95%] m-auto border border-[#707070] bg-[#200407] rounded-[28px] '>
										{btnContent}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const ProgramRegistrationPolicyModal = () => {
	return (
		<Fragment>
			{ReactDom.createPortal(
				<ProgramRegistrationPolicy />,
				document.getElementById("program_registration_policyModal")
			)}
		</Fragment>
	);
};

export default ProgramRegistrationPolicyModal;
