import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Import components
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
	MadaPayment,
	Navbar,
	ProgramRegistrationPolicyModal,
} from "../../components";

// MUI
import { MenuItem, Select } from "@mui/material";

// context
import Context from "../../context/Context";

// import css styles files
import "../../index.css";
import styles from "./RegistrationFormForTrainingProg.module.css";

// import icons and images
import { BsCheck2 } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { QuestionImage } from "../../assets/images";
import {
	ArrowUp,
	CloseCircleIcon,
	Logo,
	RedStarIcon,
} from "../../assets/icons";

// connect with api
import axios from "axios";
import useFetch from "../../Hooks/UseFetch";

// custom styles
const selectStyle = {
	fontFamily: "Tajawal",

	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "1px solid #C6C6C6",
	},
	".MuiSelect-select": {
		paddingRight: "10px !important",
	},

	".MuiSelect-icon": { right: "92%" },
};

const selectItemStyle = {
	display: "flex",
	justifyContent: "space-between",
	fontFamily: "Tajawal",

	backgroundColor: "inherit",
	color: "#707070",
	"&:hover": {
		backgroundColor: "#FDF1F3",
		color: "#200407",
	},
	"&.Mui-selected": {
		backgroundColor: "#FDF1F3",
		color: "#200407",
	},
};

// demo packages data
const packageDemoData = [
	{ id: 1, package_name: "الباقة الإقتصادية", package_price: "12,000" },
	{ id: 2, package_name: "باقة الأعمال", package_price: "14,000" },
];

// -----------------------------------------------------------------------------------------------------------------
const RegistrationFormForTrainingProg = () => {
	const navigate = useNavigate();

	// to open this page in the top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [isKSA, setIsKSA] = useState("");
	// register values
	const [registerValues, setRegisterValues] = useState({
		package_id: localStorage?.getItem("packageId") || "",
		fullName: "",
		nationality_id: "",
		country_id: "",
		city_id: "",
		phonenumber: "",
		email: "",
		specialization_id: "",
		discountCode: "",
	});

	// The Select APIs
	// ------------------------------------------------------------------------------
	const { fetchedData: nationalities } = useFetch(
		"https://api.fayezbinsaleh.me/api/nationalities"
	);

	const { fetchedData: areas } = useFetch(
		`https://api.fayezbinsaleh.me/api/areas/${registerValues?.country_id || 1}`
	);
	// --------------------------------------------------------------------------------

	const { fetchedData: countries } = useFetch(
		"https://api.fayezbinsaleh.me/api/countries"
	);
	// --------------------------------------------------------------------------------

	const { fetchedData: specializations } = useFetch(
		"https://api.fayezbinsaleh.me/api/specializations"
	);
	// --------------------------------------------------------------------------------

	// to get the current key of country
	const countriesKey =
		countries?.data?.countries?.find(
			(country) => country?.id === parseInt(registerValues?.country_id)
		) || "";

	//-----------------------------------------------------------------------------------------------

	// to handle Count or hit hint modal
	const closeHintRegisterContext = useContext(Context);
	const { closeHintRegister, setCloseHintRegister } = closeHintRegisterContext;
	//-----------------------------------------------------------------------------------------------

	//-----------------------------------------------------------------------------------------------

	// To Handle open  Training program registration policy, and Precautions while traveling to China,
	const PolicyModalContext = useContext(Context);
	const {
		setOpenMoreInfoModal,
		setTitle,
		setBtnContent,
		acceptCondition,
		acceptPolicy,
	} = PolicyModalContext;
	//-----------------------------------------------------------------------------------------------

	// use this function to pass title and description to the modal
	const handleOpenPolicyModal = (title, btnContent) => {
		setTitle(title);
		setBtnContent(btnContent);
		setOpenMoreInfoModal(true);
		window.scrollTo(80, 0);
	};
	//-----------------------------------------------------------------------------------------------

	// handle onchange values
	const handleRegisterValues = (event) => {
		const { name, value } = event.target;

		setRegisterValues((prevState) => {
			return { ...prevState, [name]: value };
		});
	};
	//-----------------------------------------------------------------------------------------------

	// To handle errors
	const [registerErrors, setRegisterErrors] = useState({
		package_id: "",
		fullName: "",
		nationality: "",
		country: "",
		city: "",
		phonenumber: "",
		email: "",
		specialization: "",
		discountCode: "",
	});

	// reset errors data
	const resetRegisterErrors = () => {
		setRegisterErrors({
			package_id: "",
			fullName: "",
			nationality: "",
			country: "",
			city: "",
			phonenumber: "",
			email: "",
			specialization: "",
			discountCode: "",
		});
	};

	/** -----------------------------------------------------------------------------------------------------------
	 *  	=> TO HANDLE THE REG_EXPRESS <=
	 *  ------------------------------------------------- */
	const FULL_NAME_REGEX =
		/^[\p{L}\p{M}\p{Zs}.'-]+(\s[\p{L}\p{M}\p{Zs}.'-]+){2,}$/u;
	const PHONE_REGEX = /^(5\d{8})$/;
	const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

	const [validFullName, setValidFullName] = useState(false);
	const [fullNameFocus, setFullNameFocus] = useState(false);
	const [validPhoneNumber, setValidPhoneNumber] = useState(false);
	const [phoneNumberFocus, setPhoneNumberFocus] = useState(false);
	const [validEmail, setValidEmail] = useState(false);
	const [emailFocus, setEmailFocus] = useState(false);

	// TO HANDLE VALIDATION FOR OWNER NAME
	useEffect(() => {
		const fullNameValidation = FULL_NAME_REGEX.test(registerValues?.fullName);
		setValidFullName(fullNameValidation);
	}, [registerValues?.fullName]);

	// TO HANDLE VALIDATION STORE PHONE NUMBER
	useEffect(() => {
		const phoneNumberValidation = PHONE_REGEX.test(registerValues?.phonenumber);
		setValidPhoneNumber(phoneNumberValidation);
	}, [registerValues?.phonenumber]);

	// TO HANDLE VALIDATION FOR EMAIL
	useEffect(() => {
		const emailValidation = EMAIL_REGEX.test(registerValues?.email);
		setValidEmail(emailValidation);
	}, [registerValues?.email]);

	/**
	 * ---------------------------------------------------------------
	 * handle connect wit api functions
	 *-----------------------------------------------------------------
	 */

	// to send info cart to api
	const handleSendCartInfoToServer = (cart_id, tran_ref, cart_amount) => {
		// send data to api
		let formData = new FormData();
		formData.append("cart_id", cart_id);
		formData.append("tran_ref", tran_ref);
		formData.append("cart_amount", cart_amount);

		axios
			.post(`https://api.fayezbinsaleh.me/api/paid`, formData)

			.then((res) => {
				if (res?.data?.success === true && res?.data?.data?.status === 200) {
					console.log(res);
				} else {
					console.log(res);
				}
			});
	};

	// To send Data to Server
	const sendCustomerServiceRequest = () => {
		resetRegisterErrors();
		// send data to api
		let formData = new FormData();

		formData.append("package_id", registerValues?.package_id);
		formData.append("name", registerValues?.fullName);
		formData.append("nationality_id", registerValues?.nationality_id);
		formData.append("country_id", registerValues?.country_id);
		formData.append("area_id", registerValues?.city_id);

		formData.append("email", registerValues?.email);
		formData.append(
			"phonenumber",
			`${countriesKey?.country_code}${registerValues?.phonenumber}`
		);
		formData.append("specialization_id", registerValues?.specialization_id);
		formData.append("discountCode", registerValues?.discountCode);

		axios
			.post(`https://api.fayezbinsaleh.me/api/subscription`, formData)

			.then((res) => {
				if (res?.data?.success === true && res?.data?.data?.status === 200) {
					// send cart info to api
					handleSendCartInfoToServer(
						res?.data?.data?.response?.cart_id,
						res?.data?.data?.response?.tran_ref,
						res?.data?.data?.response?.cart_amount
					);

					//  navigate user to payment get way website
					window.location.href = res?.data?.data?.response?.redirect_url;
				} else {
					setRegisterErrors({
						...registerErrors,
						package_id: res?.data?.message?.en?.package_id[0],
						fullName: res?.data?.message?.en?.name?.[0],
						nationality: res?.data?.message?.en?.nationality_id?.[0],
						country: res?.data?.message?.en?.country_id?.[0],
						city: res?.data?.message?.en?.area_id?.[0],
						phonenumber: res?.data?.message?.en?.phonenumber?.[0],
						email: res?.data?.message?.en?.email?.[0],
						specialization: res?.data?.message?.en?.specialization_id?.[0],
						discountCode: res?.data?.message?.en?.specialization_id?.[0],
					});
				}
			});
	};

	// ------------------------------------------------------------------------------------------------------
	return (
		<HelmetProvider>
			<Helmet>
				<title>نموذج التسجيل في البرنامج التدريبي</title>
			</Helmet>

			<div className={`openPages bg-[#FDF1F3] h-full overflow-y-auto `}>
				{/* Navbar Component */}
				<Navbar
					background={"bg-red"}
					pageTitle={
						<img
							onClick={() => navigate("/")}
							className='h-[53px] max-w-[98px] w-auto cursor-pointer'
							src={Logo}
							alt='website logo'
						/>
					}
				/>
				{/*------------------------------------------*/}
				<div className='container'>
					{/* Count Modal */}
					<div
						className={`${
							closeHintRegister ? "flex" : "hidden"
						} relative -top-5 justify-start items-center gap-1 pr-[2.5rem]`}>
						<div className=' w-28 h-10  bg-yellow flex flex-1 items-center justify-center  rounded text-[#E62237] text-lg font-bold '>
							متبقي 4 مقاعد
						</div>
						<CloseCircleIcon
							className=' cursor-pointer'
							onClick={() => setCloseHintRegister(false)}
						/>
					</div>

					{/* back to home icon */}
					<div className='flex justify-start gap-6 mb-5'>
						<ArrowUp
							className='w-[34px] h-[34px] cursor-pointer'
							onClick={() => navigate("/subscribe-training-program")}
						/>

						<div className=' h-[46px] p-1 flex justify-center items-center'>
							<h2
								className={`page_title text-center font-Tajawal font-bold text-[16px] text-[#A1182F]`}>
								<span className='block'>
									نموذج التسجيل في البرنامج التدريبي{" "}
								</span>
								<span> ( الرحلة التجارية إلى الصين)</span>
							</h2>
						</div>
					</div>

					{/* form container */}
					<div>
						{/* باقة الإشتراك */}
						<div
							className={`${styles.package_shadow} p-4  bg-white rounded-[10px] mb-4`}>
							<h4
								className={` text-[16px] font-Tajawal font-normal text-[#707070] pb-4 flex justify-start items-baseline gap-1`}>
								<span>باقة الإشتراك </span>{" "}
								<span>
									<RedStarIcon />
								</span>
							</h4>

							<Select
								className={`w-full h-[48px]   p-2 ${
									registerErrors?.package_id
										? "border-[1px] border-solid border-[fc012cff]"
										: "border-none"
								}`}
								sx={selectStyle}
								displayEmpty
								IconComponent={IoIosArrowDown}
								name='package_id'
								onChange={handleRegisterValues}
								value={registerValues.package_id}
								renderValue={(selected) => {
									if (registerValues.package_id === "") {
										return (
											<span className='text-[14px] font-normal font-Tajawal text-[#C6C6C6]'>
												اختر الباقة
											</span>
										);
									}
									const result = packageDemoData?.filter(
										(item) => item?.id === parseInt(selected)
									);

									return (
										<div className='flex justify-between items-center pl-2'>
											<span className='font-bold'>
												{result[0]?.package_name}
											</span>
											<span className='font-bold'>
												{result[0]?.package_price}
												<span className='pr-1 font-normal'>ر.س</span>
											</span>
										</div>
									);
								}}>
								{packageDemoData?.map((item) => (
									<MenuItem
										sx={selectItemStyle}
										value={item?.id}
										key={item?.id}>
										<span className=''>{item?.package_name}</span>
										<span className='font-bold'>
											{item?.package_price}
											<span className='pr-1 font-normal'>ر.س</span>
										</span>
									</MenuItem>
								))}
							</Select>

							<span className=' text-[14px] text-[#fc012cff] '>
								{registerErrors?.package_id && registerErrors?.package_id}
							</span>
						</div>

						{/* الاسم ثلاثي*/}
						<div
							className={`${styles.package_shadow} p-4  bg-white rounded-[10px] mb-4`}>
							<h4
								className={`text-[16px] font-Tajawal font-normal text-[#707070] pb-4 flex justify-start items-baseline gap-1 `}>
								<span>الاسم ثلاثي</span>
								<span>
									<RedStarIcon />
								</span>
							</h4>
							<input
								type='text'
								name='fullName'
								onChange={handleRegisterValues}
								value={registerValues.fullName}
								aria-invalid={validPhoneNumber ? "false" : "true"}
								aria-describedby='fullName'
								onFocus={() => setFullNameFocus(true)}
								onBlur={() => setFullNameFocus(true)}
								placeholder='ادخل الإسم'
								className={` w-full h-[30px] ${
									registerErrors.fullName ||
									(fullNameFocus &&
										registerValues?.fullName &&
										!validFullName) ||
									(fullNameFocus && registerValues.fullName.trim() === "")
										? " border-b-[#fc012cff] "
										: "border-b-[#C6C6C6] "
								} focus:outline-none placeholder:text-[#C6C6C6]  border-b-[1px]  text-[14px] font-normal font-Tajawal p-2 `}
							/>

							{/* Handle errors and validation messages */}
							<span className=' text-[14px] text-[#fc012cff] '>
								{fullNameFocus &&
									registerValues.fullName.trim() === "" &&
									"يرجي ادخال الاسم الثلاثي!"}
								{registerErrors.fullName && registerErrors.fullName}
							</span>
							<div
								id='fullName'
								className={
									fullNameFocus && registerValues?.fullName && !validFullName
										? "block text-[14px] text-[#fc012cff] "
										: "hidden"
								}>
								يرجي كتابه الاسم بالكامل كما هو في الهوية.
							</div>
						</div>

						{/* الجنسية */}
						<div
							className={`${styles.package_shadow} p-4  bg-white rounded-[10px] mb-4`}>
							<h4
								className={`text-[16px] font-Tajawal font-normal text-[#707070] pb-4 flex justify-start items-baseline gap-1`}>
								<span>الجنسية</span>
								<span>
									<RedStarIcon />
								</span>
							</h4>

							<Select
								className={`w-full h-[48px]   p-2 ${
									registerErrors.nationality
										? "border-[1px] border-solid border-[fc012cff]"
										: "border-none"
								}`}
								sx={selectStyle}
								displayEmpty
								IconComponent={IoIosArrowDown}
								name='nationality_id'
								onChange={handleRegisterValues}
								value={registerValues.nationality_id}
								renderValue={(selected) => {
									if (registerValues.nationality_id === "") {
										return (
											<span className='text-[14px] font-normal font-Tajawal text-[#C6C6C6]'>
												اختر الجنسية
											</span>
										);
									}
									const result = nationalities?.data?.nationalities?.filter(
										(item) => item?.id === selected
									);

									return result[0]?.nationality_name;
								}}>
								{nationalities?.data?.nationalities?.map((item) => (
									<MenuItem
										sx={selectItemStyle}
										value={item?.id}
										key={item?.id}>
										{item?.nationality_name}
									</MenuItem>
								))}
							</Select>

							<span className=' text-[14px] text-[#fc012cff] '>
								{registerErrors.nationality && registerErrors.nationality}
							</span>
						</div>

						{/* الدولة */}
						<div
							className={`${styles.package_shadow} p-4  bg-white rounded-[10px] mb-4`}>
							<h4
								className={`text-[16px] font-Tajawal font-normal text-[#707070] pb-4 flex justify-start items-baseline gap-1`}>
								<span>الدولة</span>
								<span>
									<RedStarIcon />
								</span>
							</h4>

							<Select
								className={`w-full h-[48px]   p-2 ${
									registerErrors.country
										? "border-[1px] border-solid border-[fc012cff]"
										: "border-none"
								}`}
								sx={selectStyle}
								displayEmpty
								IconComponent={IoIosArrowDown}
								name='country_id'
								onChange={handleRegisterValues}
								value={registerValues.country_id}
								renderValue={(selected) => {
									if (registerValues.country_id === "") {
										return (
											<span className='text-[14px] font-normal font-Tajawal text-[#C6C6C6]'>
												اختر الدولة
											</span>
										);
									}

									const result = countries?.data?.countries?.filter(
										(item) => item?.id === parseInt(selected)
									);
									setIsKSA(result[0]?.country_name);
									return result[0]?.country_name;
								}}>
								{countries?.data?.countries?.map((item) => (
									<MenuItem
										sx={selectItemStyle}
										value={item?.id}
										key={item?.id}>
										<span>{item?.country_name}</span>
										<span>{item?.country_code}</span>
									</MenuItem>
								))}
							</Select>

							<span className=' text-[14px] text-[#fc012cff] '>
								{registerErrors.country && registerErrors.country}
							</span>
						</div>

						{/* المنطقة */}
						{isKSA === "المملكة العربية السعودية" && (
							<div
								className={`${styles.package_shadow} p-4  bg-white rounded-[10px] mb-4`}>
								<h4
									className={`text-[16px] font-Tajawal font-normal text-[#707070] pb-4 flex justify-start items-baseline gap-1`}>
									<span>المنطقة</span>
									<span>
										<RedStarIcon />
									</span>
								</h4>

								<Select
									className={`w-full h-[48px]   p-2 ${
										registerErrors.city
											? "border-[1px] border-solid border-[fc012cff]"
											: "border-none"
									}`}
									sx={selectStyle}
									displayEmpty
									IconComponent={IoIosArrowDown}
									name='city_id'
									onChange={handleRegisterValues}
									value={registerValues.city_id}
									renderValue={(selected) => {
										if (registerValues.city_id === "") {
											return (
												<span className='text-[14px] font-normal font-Tajawal text-[#C6C6C6]'>
													اختر المنطقة
												</span>
											);
										}
										const result = areas?.data?.areas?.filter(
											(item) => item?.id === parseInt(selected)
										);

										return result[0]?.area_name;
									}}>
									{areas?.data?.areas?.map((item) => (
										<MenuItem
											sx={selectItemStyle}
											value={item?.id}
											key={item?.id}>
											{item?.area_name}
										</MenuItem>
									))}
								</Select>

								<span className=' text-[14px] text-[#fc012cff] '>
									{registerErrors.city && registerErrors.city}
								</span>
							</div>
						)}

						{/* رقم الجوال */}
						<div
							className={`${styles.package_shadow} p-4  bg-white rounded-[10px] mb-4`}>
							<h4
								className={`text-[16px] font-Tajawal font-normal text-[#707070] pb-4 flex justify-start items-baseline gap-1`}>
								<span>رقم الجوال</span>
								<span>
									<RedStarIcon />
								</span>
							</h4>
							<div
								className={`w-full h-[30px] flex justify-start items-center  ${
									(phoneNumberFocus &&
										registerValues.phonenumber.trim() === "") ||
									registerErrors.phonenumber
										? " border-b-[#fc012cff]"
										: "border-b-[#C6C6C6] "
								}  border-b-[1px] `}>
								<input
									type='tel'
									name='phonenumber'
									onChange={handleRegisterValues}
									value={registerValues.phonenumber}
									placeholder='أدخل رقم الجوال'
									aria-invalid={validPhoneNumber ? "false" : "true"}
									aria-describedby='email'
									onFocus={() => setPhoneNumberFocus(true)}
									onBlur={() => setPhoneNumberFocus(true)}
									className={` w-full h-full border-none focus:outline-none placeholder:text-[#C6C6C6]  text-[14px] font-normal font-Tajawal p-2 `}
								/>
								{/* Country key */}
								<div className=''>{countriesKey?.country_code}</div>
							</div>

							{/* Handle errors and validation messages */}
							<span className=' text-[14px] text-[#fc012cff] '>
								{phoneNumberFocus &&
									registerValues.phonenumber.trim() === "" &&
									"يرجي ادخال رقم الجوال!"}
								{registerErrors.phonenumber && registerErrors.phonenumber}
							</span>
						</div>

						{/* البريد الإلكتروني  */}
						<div
							className={`${styles.package_shadow} p-4  bg-white rounded-[10px] mb-4`}>
							<h4
								className={`text-[16px] font-Tajawal font-normal text-[#707070] pb-4 flex justify-start items-baseline gap-1`}>
								<span> البريد الإلكتروني</span>
								<span>
									<RedStarIcon />
								</span>
							</h4>
							<input
								type='email'
								name='email'
								onChange={handleRegisterValues}
								value={registerValues.email}
								aria-invalid={validEmail ? "false" : "true"}
								aria-describedby='email'
								onFocus={() => setEmailFocus(true)}
								onBlur={() => setEmailFocus(true)}
								placeholder='ادخل البريد الإلكتروني'
								className={` w-full h-[30px] ${
									registerErrors.email ||
									(emailFocus && registerValues?.email && !validFullName) ||
									(emailFocus && registerValues.email.trim() === "")
										? " border-b-[#fc012cff] "
										: "border-b-[#C6C6C6] "
								} focus:outline-none placeholder:text-[#C6C6C6]  border-b-[1px]  text-[14px] font-normal font-Tajawal p-2 `}
							/>

							{/* Handle errors and validation messages */}
							<span className=' text-[14px] text-[#fc012cff] '>
								{emailFocus &&
									registerValues.email.trim() === "" &&
									"يرجي ادخال  البريد الإلكتروني!"}
								{registerErrors.email && registerErrors.email}
							</span>
							<div
								id='email'
								className={
									emailFocus && registerValues?.email && !validEmail
										? "block text-[14px] text-[#fc012cff] "
										: "hidden"
								}>
								يرجي كتابة البريد الالكتروني بشكل صحيح.
							</div>
						</div>

						{/* الاهتمام والتخصص  */}
						<div
							className={`${styles.package_shadow} p-4  bg-white rounded-[10px] mb-6`}>
							<h4
								className={`text-[16px] font-Tajawal font-normal text-[#707070] pb-4 flex justify-start items-baseline gap-1`}>
								<span>الاهتمام والتخصص </span>
								<span>
									<RedStarIcon />
								</span>
							</h4>

							<Select
								className={`w-full h-[48px]   p-2 ${
									registerErrors.specialization
										? "border-[1px] border-solid border-[fc012cff]"
										: "border-none"
								}`}
								sx={selectStyle}
								displayEmpty
								IconComponent={IoIosArrowDown}
								name='specialization_id'
								onChange={handleRegisterValues}
								value={registerValues.specialization_id}
								renderValue={(selected) => {
									if (registerValues.specialization_id === "") {
										return (
											<span className='text-[14px] font-normal font-Tajawal text-[#C6C6C6]'>
												اختر الاهتمام أو التخصص الذي يناسبك
											</span>
										);
									}
									const result = specializations?.data?.specializations?.filter(
										(item) => item?.id === parseInt(selected)
									);

									return result[0]?.specialization;
								}}>
								{specializations?.data?.specializations?.map((item) => (
									<MenuItem
										sx={selectItemStyle}
										value={item?.id}
										key={item?.id}>
										{item?.specialization}
									</MenuItem>
								))}
							</Select>
							<span className=' text-[14px] text-[#fc012cff] '>
								{registerErrors.specialization && registerErrors.specialization}
							</span>
						</div>

						{/*  كود الخصم  */}
						<div
							className={`${styles.package_shadow} p-4  bg-white rounded-[10px] mb-4`}>
							<h4
								className={`text-[16px] font-Tajawal font-normal text-[#707070] pb-4 flex justify-start items-baseline `}>
								<span>كود الخصم </span>
							</h4>
							<input
								type='text'
								name='discountCode'
								onChange={handleRegisterValues}
								value={registerValues.discountCode}
								placeholder='ادخل  كود الخصم '
								className={` w-full h-[30px] border-b-[#C6C6C6] focus:outline-none placeholder:text-[#C6C6C6]  border-b-[1px]  text-[14px] font-normal font-Tajawal p-2 `}
							/>

							{/* Handle errors and validation messages */}
						</div>

						<div className='mb-6'>
							<p className='text-[#FF9F1A] text-[14px] font-normal font-Tajawal mb-4'>
								قبل الاشتراك يجب الموافقة على السياسة والشروط
							</p>

							<div className='flex justify-start items-center mb-3'>
								<span
									onClick={() =>
										handleOpenPolicyModal(
											"سياسة التسجيل في البرنامج التدريبي",
											"أوافق على تكملة التسجيل في البرنامج"
										)
									}
									className='text-lg text-[#1A5FFF] font-medium font-Tajawal cursor-pointer border-b-[1px] border-b-[#1A5FFF] pe-1'>
									سياسة التسجيل في البرنامج التدريبي
								</span>

								<span
									className={`${
										acceptPolicy ? "bg-[#3ae275ff]" : "bg-[#C6C6C6]"
									} w-6 h-6 flex justify-center items-center  rounded-full`}>
									<BsCheck2 className={`${acceptPolicy && "text-white"}`} />
								</span>
							</div>

							<div className='flex justify-start items-center mb-3'>
								<span
									onClick={() =>
										handleOpenPolicyModal(
											"احتياطات أثناء السفر للصين",
											"أوافق على الشروط"
										)
									}
									className='text-lg text-[#1A5FFF] font-medium font-Tajawal cursor-pointer border-b-[1px] border-b-[#1A5FFF] pe-1'>
									احتياطات أثناء السفر للصين
								</span>
								<span
									className={`${
										acceptCondition ? "bg-[#3ae275ff]" : "bg-[#C6C6C6]"
									} w-6 h-6 flex justify-center items-center  rounded-full`}>
									<BsCheck2 className={`${acceptCondition && "text-white"}`} />
								</span>
							</div>
						</div>

						{/* Submit Register button */}
						<button
							onClick={sendCustomerServiceRequest}
							className={`rounded-[24px] w-full h-[56px] text-lg font-Tajawal font-medium mb-6 ${
								styles.btn_shadow
							}   ${
								registerValues?.package_id &&
								registerValues?.fullName &&
								registerValues?.nationality_id &&
								registerValues?.country_id &&
								registerValues?.phonenumber &&
								registerValues?.email &&
								registerValues?.specialization_id
									? "bg-[#200407] text-white cursor-pointer"
									: "bg-[#C6C6C6] text-[#707070] cursor-not-allowed"
							} `}
							disabled={
								registerValues?.package_id &&
								registerValues?.fullName &&
								registerValues?.nationality_id &&
								registerValues?.country_id &&
								registerValues?.phonenumber &&
								registerValues?.email &&
								registerValues?.specialization_id
									? false
									: true
							}>
							التسجيل والدفع
						</button>
					</div>

					{/* Common questions */}
					<div
						onClick={() => navigate("/common-questions")}
						className='h-[120px] w-full bg-[#a1192eff] flex flex-col items-center justify-center cursor-pointer'>
						<img src={QuestionImage} alt='' loading='lazy' />
						<h4 className='text-2xl text-white font-Tajawal font-medium border-b-2 border-b-[white] '>
							الأسئلة الشائعة
						</h4>
					</div>
				</div>
			</div>

			{/* Program Registration Policy Modal Component */}
			<ProgramRegistrationPolicyModal />

			{/* Mada Icon */}
			<MadaPayment />
		</HelmetProvider>
	);
};

export default RegistrationFormForTrainingProg;
