import React, { useEffect, useState } from "react";
import { MadaIcon } from "../../assets/icons";

const MadaPayment = () => {
	const [showIcon, setShowIcon] = useState(false);

	useEffect(() => {
		const debounce = setInterval(() => {
			setShowIcon(true);
		}, 2000);

		return () => clearInterval(debounce);
	}, [showIcon]);
	return (
		<div
			className={`${
				showIcon ? "left-0" : "left-[-200px]"
			} fixed  bottom-[-5px] flex items-center justify-center pointer-events-none z-50  transition-all ease-in-out delay-900`}>
			<MadaIcon className='w-16 h-16' />
		</div>
	);
};

export default MadaPayment;
