import React, { useContext } from "react";

// import css styles
import "../../index.css";
import styles from "./ArticleBlock.module.css";

// Import icons
import { MoreInfo, Star } from "../../assets/icons";

// import components
import MoreInfoModal from "../moreInfoModal/MoreInfoModal";

// Import context
import Context from "../../context/Context";
import {
	ActivityImage,
	ArriveToChinaImage,
	FunDayImage,
	GiftsImage,
	ProgramAdvantagesImage,
	RulesImage,
	SkillsImage,
	TeamWorkForServicesImage,
	TraningBagImage,
	TransportingImage,
} from "../../assets/images";

// Array Of info
const articles = [
	{
		id: 1,
		title: "مميزات البرنامج التدريبي",
		// image: ProgramAdvantagesImage,
		items: [
			{ id: 1, title: "مرخص من المملكة العربية السعودية" },
			{
				id: 2,
				title: "مدة البرنامج التدريبي 12 يوم",
			},
			{
				id: 3,
				title: "أكثر من 60 ساعه ",
			},
			{
				id: 4,
				title: "تدريب 4 أيام نظري  ",
			},
			{
				id: 5,
				title: "تدريب 8 أيام عملي  ",
			},
			{ id: 6, title: "زيارة اكثر من مدينة (تجارية ضخمة)" },
		],
	},
	{
		id: 2,
		title: "فريق عمل في خدمتكم",
		// image: TeamWorkForServicesImage,
		items: [
			{ id: 1, title: "مترجم خاص مع الرجال" },
			{ id: 2, title: "مترجم خاص مع النساء" },
		],
	},
	{
		id: 3,
		title: "أنشطة ومهام للمتدربين",
		// image: ActivityImage,
		items: [{ id: 1, title: "تحديات وجوائز قيمة للفائزين" }],
	},
	{
		id: 4,
		title: "الوصول إلي الصين",
		// image: ArriveToChinaImage,
		items: [
			{ id: 1, title: "الاستقبال والمغادرة من وإلي المطار" },
			{ id: 2, title: "هدايا للمشتركين عند الوصول" },
			{ id: 3, title: "السكن في فندق 5 نجوم آمن للعرب " },
			{ id: 4, title: "جميع الأماكن التي سيتم زيارتها تمتاز بالأمان" },
		],
	},
	{
		id: 5,
		title: "التنقلات",
		// image: TransportingImage,
		items: [{ id: 1, title: "التاكسي-الباص-المترو" }],
	},
	{
		id: 6,
		title: "حقيبة تدريبية تحتوي علي :",
		// image: TraningBagImage,
		items: [
			{ id: 1, title: "مذكرة تشمل شرائح البرنامج التدريبي" },
			{ id: 2, title: "نماذج جاهزة لدراسة الجدوي" },
			{ id: 3, title: "نماذج لخطط تسويقية" },
			{ id: 4, title: "جداول نشر المحتوي" },
			{ id: 5, title: "نماذج ادارة الموظفين وتقييم الاداء" },
			{ id: 6, title: "نماذج عمل استراتيجيات المشروع" },
		],
	},
	{
		id: 7,
		title: "يوم سياحي ",
		// image: FunDayImage,
		items: [{ id: 1, title: "يكون اخر يوم في الصين حسب الرغبة" }],
	},
	{
		id: 8,
		title: "مهارات يكتسبها المتدرب",
		// image: SkillsImage,
		items: [
			{ id: 1, title: "استغلال الفرص التجارية" },
			{ id: 2, title: "التفاوض مع الصينيين " },
			{ id: 3, title: " تعلم الكلمات الصينية" },
			{ id: 4, title: "عمل دراسة جدوى " },
			{ id: 5, title: " صناعة البراند الخاص" },
			{ id: 6, title: " التصوير التجاري" },
			{ id: 7, title: "عمل الخطط التسويقية " },
			{ id: 8, title: " إدارة المشروع " },
			{ id: 9, title: "إدارة فريق العمل " },
			{ id: 10, title: "معرفة أسواق جملة الجملة في الصين" },
			{ id: 11, title: " يكون تاجر ومتدرب ومستشار" },
		],
	},
	{
		id: 9,
		title: "تعليمات ونصائح يحصل عليها المتدرب",
		// image: RulesImage,
		items: [
			{ id: 1, title: " المنتجات التي يمكن حملها في الطائرة" },
			{ id: 2, title: " لائحة سياسة الاستيراد الي الى السعودية  " },
			{ id: 3, title: "  المنتجات التي تحتاج تراخيص " },
		],
	},
	{
		id: 10,
		title: " الهدايا التجارية للمشتركين",
		// image: GiftsImage,
		items: [
			{ id: 1, title: " متجر الكتروني مجانا لكل مشترك" },
			{ id: 2, title: " تصميم لوجو مجانا لكل مشترك " },
			{ id: 3, title: " إعلان مجاني للمشتركين المتميزين " },
			{ id: 4, title: "  الفرصة في الدخول في مشروع مشترك " },
			{ id: 5, title: " الانظمام في كونتينر مشترك  " },
		],
	},
];

const ArticleBlock = () => {
	const ArticleInfoModal = useContext(Context);
	const { setOpenMoreInfoModal, setDescription, setTitle } = ArticleInfoModal;

	// handle open more info modal
	const handleOpenMoreInfoModal = (description, title) => {
		setOpenMoreInfoModal(true);
		setDescription(description);
		setTitle(title);
		window.scrollTo(80, 0);
	};

	return (
		<>
			<div className='mx-w[430px] '>
				{articles.map((article) => (
					<div className='mb-5' key={article?.id}>
						<h3 className='text-lg font-Tajawal font-bold text-black mb-2'>
							{article?.title}
						</h3>
						<div className='article-content w-100 rounded-lg bg-white'>
							{/*
							<div className={`${styles.article_image_box} mb-2 `}>
							
								<img src={article?.image} alt={article?.title} />
								</div>
							*/}

							<div className='py-2 pr-2 pl-1'>
								<ul className={`${styles.article_list}`}>
									{article?.items?.map((item) => (
										<li
											key={item?.id}
											className={`h-6 flex justify-start items-center gap-2 text-lg font-Tajawal font-normal text-black mb-4`}>
											<div className='w-[23px] h-[20px] max-w-full'>
												<Star className='w-full' />
											</div>
											{item?.title}
											{item?.description && (
												<div className='w-[24px] h-[24px] max-w-full'>
													<MoreInfo
														className='w-full cursor-pointer'
														onClick={() =>
															handleOpenMoreInfoModal(
																item?.description,
																item?.title
															)
														}
													/>
												</div>
											)}
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				))}
			</div>

			<MoreInfoModal />
		</>
	);
};

export default ArticleBlock;
