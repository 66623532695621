import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

// import component
import CustomerServiceForm from "../../components/customerServiceForm/CustomerServiceForm";
import { Navbar } from "../../components";

// import css styles
import "../../index.css";
import styles from "./CustomerService.module.css";

// icons and images
import { ArrowRight, WhatsappIcon } from "../../assets/icons";
import ReactWhatsapp from "react-whatsapp";

const CustomerService = () => {
	const navigate = useNavigate();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<HelmetProvider>
			<Helmet>
				<title> خدمة العملاء</title>
			</Helmet>

			<div className={`openPages bg-[#FDF1F3] h-full overflow-y-auto pb-8`}>
				{/*Navbar */}
				<Navbar
					background={"bg-red"}
					pageTitle={
						<>
							<h3 className=' w-64 text-white text-xl font-Tajawal font-normal '>
								خدمة العملاء
							</h3>
						</>
					}
				/>
				{/*------------------------------------------*/}

				<div className='container'>
					{/* back to home icon */}
					<div className='flex justify-start gap-4 mb-8'>
						<ArrowRight
							className=' w-[34px] h-[34px] cursor-pointer'
							onClick={() => navigate("/common-questions")}
						/>
						<div className=' text-right text-xl font-medium font-Tajawal text-black pl-4'>
							قم بالتواصل مع خدمة العملاء من خلال تعبئة النموذج في الأسفل
						</div>
					</div>

					{/* Customer Service Form  Component*/}
					<CustomerServiceForm />

					{/* packages info */}
					<button className='w-full'>
						<ReactWhatsapp
							number='966505549056'
							message='السلام عليكم ورحمه الله وبركاته, '
							className={` flex justify-center items-center text-center gap-2 ${styles.btn_drop_shadow} cursor-pointer w-full h-[49px] border-[1px] border-[#200407] bg-transparent rounded-[28px]  mb-5`}>
							<WhatsappIcon className='w-6 h-6' />
							<h4 className='text-black text-xl font-Tajawal font-medium'>
								تواصل عبر الواتساب
							</h4>
						</ReactWhatsapp>
					</button>
				</div>
			</div>
		</HelmetProvider>
	);
};

export default CustomerService;
