import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

// import components
import { BlogArticles, Navbar } from "../../components";

// import css styles
import "../../index.css";
import styles from "./Blog.module.css";

// import icons and images
import { BlogNavbarBg } from "../../assets/images";
import { ArrowRight, SearchIcon } from "../../assets/icons";

const Blog = () => {
	const navigate = useNavigate();

	const [articlesSearch, setArticlesSearch] = useState("");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<HelmetProvider>
			<Helmet>
				<title> المدونة </title>
				<meta name='description' content='المدونة' />
			</Helmet>

			<div className={`openPages pb-12`}>
				{/*Navbar */}
				<Navbar
					background={"bg-red"}
					NavbarBgImg={BlogNavbarBg}
					pageTitle={
						<h3 className=' w-64  text-white text-xl font-Tajawal font-normal '>
							المدونة
						</h3>
					}
				/>

				{/*------------------------------------------*/}
				<div className='container'>
					{/*back to home icon*/}
					<div
						className={`${styles.arrow_right} flex justify-start items-center gap-4 mb-5 pl-5`}>
						<ArrowRight
							className='w-[34px] h-[34px] cursor-pointer'
							onClick={() => navigate("/")}
						/>

						{/* Search input for all blog articles */}
						<div className=' flex justify-start items-center border-[1px] border-[#FF92A5] rounded-[28px] w-full h-14 gap-1 px-3'>
							<SearchIcon className='w-6 h-6' />
							<input
								className=' bg-transparent focus:outline-none focus:border-[0px] border-[0px] w-full h-full text-[16px] font-Tajawal font-normal placeholder:text[#707070] '
								name='article-search'
								placeholder='ابحث في المدونة'
								type='text'
								value={articlesSearch}
								onChange={(e) => setArticlesSearch(e.target.value)}
							/>
						</div>
					</div>

					{/* blog articles*/}
					<BlogArticles articlesSearch={articlesSearch} />
				</div>
			</div>
		</HelmetProvider>
	);
};

export default Blog;
