import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HandIcon } from "../../assets/images";

const FixedMenu = ({ posBottomValue }) => {
	const [showFixedMenu, setShowFixedMenu] = useState(false);

	useEffect(() => {
		const debounce = setTimeout(() => {
			setShowFixedMenu(true);
		}, 2000);

		return () => clearTimeout(debounce);
	}, [showFixedMenu]);

	useEffect(() => {
		const debounce = setTimeout(() => {
			setShowFixedMenu(false);
		}, 9000);

		return () => clearTimeout(debounce);
	}, [showFixedMenu]);

	return (
		<div
			className={`${showFixedMenu ? "right-0 " : "right-[-200px] "}  fixed ${
				posBottomValue ? `${posBottomValue}` : "bottom-[100px]"
			}  flex items-center justify-center  z-50 transition-all ease-in-out delay-900`}>
			<div className='flex justify-start items-center bg-black py-1 px-[6px] rounded-md cursor-pointer gap-1'>
				<div className='w-6 h-6 '>
					<img
						src={HandIcon}
						alt=''
						width='100%'
						height='100%'
						className=' animate-bouncing_left'
					/>
				</div>
				<Link
					className='text-white'
					to='/registration-form-for-the-training-program'>
					{" "}
					التسجيل والاشتراك
				</Link>
			</div>
		</div>
	);
};

export default FixedMenu;
