import React from "react";

// import components
import ReactWhatsapp from "react-whatsapp";

// Import styles files
import styles from "./WhatsappGroupBanner.module.css";

// import images and icons
import { whatsapp_banner } from "../../assets/images";
import { WhatsappIcon } from "../../assets/icons";

const WhatsappGroupBanner = () => {
	return (
		<div
			className={`${styles.whatsapp_banner}`}
			style={{ backgroundImage: `url(${whatsapp_banner}) ` }}>
			<div className='flex justify-center items-center text-center h-full cursor-pointer'>
				{/** using react Whatsapp */}
				<a
					href='https://chat.whatsapp.com/KJWR9keG0MUGGekzAWJ5kT'
					target='_blank'
					rel='noreferrer'
					className='flex justify-center items-center text-center gap-9'>
					<WhatsappIcon />
					<h4 className=' text-white text-xl font-medium'>
						انضم إلينا على جروب الواتساب
					</h4>
				</a>
			</div>
		</div>
	);
};

export default WhatsappGroupBanner;
