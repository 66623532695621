import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Context from "../../context/Context";

// icons and images
import { BlogImgOne, BlogImgTow } from "../../assets/images";
import { Star } from "../../assets/icons";

// demo blogs data
const articlesDataArray = [
	{
		id: 1,
		title: "تعرف على أشهر المواقع والتطبيقات الصينية",
		cover: BlogImgOne,
		articleContent: (
			<section className='text-right'>
				<p className='text-lg  font-normal text-[#011723] mb-5'>
					تتمتع الصين بصناعة تكنولوجيا ناشئة ومزدهرة، مما أدى إلى ظهور العديد من
					المواقع والتطبيقات الشهيرة التي تحظى بشعبية كبيرة بين المستخدمين
					:الصينيين. ومن أشهر هذه المواقع والتطبيقات ما يلي
				</p>

				{/* the websites */}
				<div className='mb-8'>
					<h4 className='bg-yellow w-max text-xl font-bold text-[#011723] mb-6 px-6 py-1 '>
						المواقع:
					</h4>
					<ul className=''>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								Baidu
							</div>
							<div className='pr-3 text-lg'>
								محرك البحث الأكثر شعبية في الصين
							</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								Tencent
							</div>
							<div className='pr-3 text-lg'>
								شركة تكنولوجيا متعددة الجنسيات تمتلك العديد من المواقع
								والتطبيقات الشهيرة، مثل
							</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								Alibaba
							</div>
							<div className='pr-3 text-lg'>
								شركة تجارة إلكترونية عالمية تمتلك العديد من المواقع والتطبيقات
								الشهيرة مثل
							</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start '>
								Sogou
							</div>
							<div className='pr-3 text-lg'>محرك بحث صيني آخر ينافس Baidu</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start '>
								360
							</div>
							<div className='pr-3 text-lg'>
								شركة تكنولوجيا صينية تمتلك العديد من المواقع والتطبيقات الشهيرة،
								مثل
							</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-6 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								Baidu
							</div>
							<div className='pr-3 text-lg'>
								محرك البحث الأكثر شعبية في الصين
							</div>
						</li>
					</ul>
				</div>

				{/* the applications */}
				<div>
					<h4 className='bg-yellow w-max text-xl font-bold text-[#011723] mb-6 px-6 py-1 '>
						التطبيقات:
					</h4>

					<ul className='list-disc'>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className=' min-w-[65px] max-w-[80px] font-bold self-start '>
								WeChat
							</div>
							<div className='pr-3 text-lg'>
								تطبيق مراسلة فورية شهير يستخدمه أكثر من مليار شخص حول العالم.
							</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								QQ
							</div>
							<div className='pr-3 text-lg'>
								تطبيق مراسلة فورية آخر شهير يستخدمه أكثر من 1.2 مليار شخص في
								الصين.
							</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								Weibo
							</div>
							<div className='pr-3 text-lg'>
								موقع تواصل اجتماعي صيني يشبه Twitter.
							</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start '>
								Taobao
							</div>
							<div className='pr-3 text-lg'>
								موقع تجارة إلكترونية صيني يشبه Amazon.{" "}
							</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								Tmall
							</div>
							<div className='pr-3 text-lg'>
								موقع تجارة إلكترونية صيني آخر يشبه Amazon.
							</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								TikTok
							</div>
							<div className='pr-3 text-lg'>
								تطبيق لمشاركة مقاطع الفيديو القصيرة.
							</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								Douyin
							</div>
							<div className='pr-3 text-lg'> نسخة صينية من TikTok.</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-6 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								Kuaishou
							</div>
							<div className='pr-3 text-lg'>
								تطبيق لمشاركة مقاطع الفيديو القصيرة.
							</div>
						</li>
					</ul>
					{/**/}
					<p className='text-xl font-medium text-[#011723] mb-6 '>
						بالإضافة إلى هذه المواقع والتطبيقات الشهيرة، هناك العديد من المواقع
						والتطبيقات الصينية الأخرى التي تحظى بشعبية كبيرة في الصين، مثل
					</p>
					<ul className='list-disc'>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								Bilibili
							</div>
							<div className='pr-3 text-lg'>موقع مشاركة مقاطع الفيديو</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start flex-wrap'>
							<div className='min-w-[65px] max-w-[95px] font-bold self-start'>
								NetEase Cloud Music
							</div>
							<div className='pr-3 text-lg'>خدمة بث الموسيقى</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								Youku
							</div>
							<div className='pr-3 text-lg'>موقع بث الفيديو</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								Douban
							</div>
							<div className='pr-3 text-lg'>موقع تقييم الأفلام والتلفزيون</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start '>
								Tmall Global
							</div>
							<div className='pr-3 text-lg'>
								موقع تجارة إلكترونية صيني يبيع المنتجات للعملاء الدوليين
							</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[95px] font-bold self-start '>
								AliExpress
							</div>
							<div className='pr-3 text-lg '>
								موقع تجارة إلكترونية صيني يشبه eBay.
							</div>
						</li>
						<li className='text-lg  font-normal text-[#011723] mb-3 flex justify-start items-start '>
							<div className='min-w-[75px] max-w-[80px] font-bold self-start '>
								Pinduoduo
							</div>
							<div className='pr-5 text-lg'>
								موقع تجارة إلكترونية صيني يبيع المنتجات بأسعار مخفضة
							</div>
						</li>

						<li className='text-lg  font-normal text-[#011723] mb-8 flex justify-start items-start '>
							<div className='min-w-[65px] max-w-[80px] font-bold self-start'>
								Meitu
							</div>
							<div className='pr-3 text-lg'>تطبيق لتحرير الصور</div>
						</li>
					</ul>

					{/**/}
					<p className='text-xl font-medium text-[#011723] mb-3'>
						تتميز المواقع والتطبيقات الصينية بمجموعة متنوعة من الميزات والفوائد
						التي تجذب المستخدمين الصينيين. على سبيل المثال، يتميز تطبيق WeChat
						بمجموعة واسعة من الميزات الاجتماعية والمالية، مما يجعله تطبيقًا
						أساسيًا لكثير من المستخدمين الصينيين. كما تتميز المواقع والتطبيقات
						الصينية بدعم اللغة الصينية، مما يجعلها سهلة الاستخدام للمستخدمين
						الصينيين.
					</p>
				</div>
			</section>
		),
	},
	{
		id: 2,
		title: "اكتشف أغرب عادات وتقاليد الشعب الصيني",
		cover: BlogImgTow,
		articleContent: (
			<section className='text-right'>
				<div className='flex justify-start items-start gap-2 text-lg font-normal text-[#011723] mb-3'>
					<div>
						<Star className='w-6 h-6' />
					</div>
					<h4>رقم 4 من الأرقام المحظور استخدامها نهائياً</h4>
				</div>
				<div className='flex justify-start items-start gap-2 text-lg font-normal text-[#011723] mb-3'>
					<div>
						<Star className='w-6 h-6' />
					</div>
					<h4>يعتبر الصينيون أن رقم 4 هو رقم شؤم لأن لفظ 4 بالصيني تعني موت</h4>
				</div>
				<div className='flex justify-start items-start gap-2 text-lg font-normal text-[#011723] mb-3'>
					<div>
						<Star className='w-6 h-6' />
					</div>
					<h4>
						محرك البحث علي بابا وهو أفضل الطرق للبحث عن البضائع الصينية <br />
						فإذا كنت زائر أو سائح في الصين فلا تستغرب أنك لن تجد محرك البحث جوجل
						او تويتر او يوتيوب او فيسبوك لأن الصين تقدم برامج بديلة منها محرك
						البحث علي بابا .
					</h4>
				</div>
				<div className='flex justify-start items-start gap-2 text-lg font-normal text-[#011723] mb-3'>
					<div>
						<Star className='w-6 h-6' />
					</div>
					<h4>
						يقدس الصينين الاحترام وعلى أن يكون الاحترام متبادل ويعتز الصينين
						بلغتهم وأعمالهم و مبتكراتهم .
					</h4>
				</div>
				<div className='flex justify-start items-start gap-2 text-lg font-normal text-[#011723] mb-3'>
					<div>
						<Star className='w-6 h-6' />
					</div>
					<h4>
						عيد الربيع الصيني يتميز الصينين بطقوس وتقاليد خاصة في الاحتفال
						بالأعياد ويعتبر عيد الربيع الصيني هو احتفال تقليدي يعود تاريخه لأكثر
						من 3000 سنة ويسمى أيضا بعيد لم الشمل حيث يلتقي جميع افراد العائلة
					</h4>
				</div>
				<div className='flex justify-start items-start gap-2 text-lg font-normal text-[#011723] mb-3'>
					<div>
						<Star className='w-6 h-6' />
					</div>
					<h4>
						يشترك الشعب الصيني مع الشعوب العربية في حبهم للشاي خاصة بعد وجبة
						العشاء
					</h4>
				</div>
				<div className='flex justify-start items-start gap-2 text-lg font-normal text-[#011723] mb-3'>
					<div>
						<Star className='w-6 h-6' />
					</div>
					<h4>
						إذا تمت دعوتك مع صينين للغذاء مثلا فيجب عليك أن لا تفرغ صحن الأكل
						الخاص بك بالكامل لأن ذلك يعني إنك لم تشبع و إلا سيملؤ صحنك من جديد
						لأن ذلك يمثل إهانة للمضيف
					</h4>
				</div>
				<div className='flex justify-start items-start gap-2 text-lg font-normal text-[#011723] mb-3'>
					<div>
						<Star className='w-6 h-6' />
					</div>
					<h4>
						أذا حضرت مقابلة تجارية مع الصينين فلا تجاوب بالنفي في أي موضوع لأن
						الصينين يعتبرون اجابتك غيرمناسبة
					</h4>
				</div>
			</section>
		),
	},
];

const BlogArticles = ({ articlesSearch }) => {
	const navigate = useNavigate();

	//  to send article content to articleContent page
	const blogContext = useContext(Context);
	const { setBlogArticleContent } = blogContext;

	// open article content page and send the current article content to it
	const handleOpenArticleContentPage = (article) => {
		setBlogArticleContent(article);
		navigate(`/blog/${article.title}`);
	};

	// to handle search function
	let articles = articlesDataArray;

	if (articlesSearch !== "") {
		articles = articlesDataArray.filter((article) =>
			article.title.includes(articlesSearch)
		);
	} else {
		articles = articlesDataArray;
	}
	// to map on array and set
	const article = articles.map((article) => (
		<div
			className='cursor-pointer mb-4'
			key={article.id}
			onClick={() => handleOpenArticleContentPage(article)}>
			<div className='relative w-full '>
				<img
					className='rounded '
					src={article.cover}
					alt={article.title}
					loading='lazy'
				/>
				<div className='absolute lef-0 top-0 w-full h-full bg-[#00000063]'>
					<h2 className='w-[309px] h-full  flex justify-center items-center m-auto   text-xl font-Tajawal font-bold text-white text-center'>
						{article.title}
					</h2>
				</div>
			</div>
		</div>
	));
	return (
		<div>
			{" "}
			{article.length === 0 ? (
				<div className='h-full w-full flex justify-center items-center text-xl text-red font-medium'>
					لا يوجد مقالات!
				</div>
			) : (
				article
			)}
		</div>
	);
};

export default BlogArticles;
